window.THEME_SETTINGS = {
    header: {
        topSearch: {
            useCategory: false
        },
        superMenu: {
            icon: "add_box"
        }
    },
    table: {
        pagination: {
            showNumbers: true,
            showFirstLastPage: true
        }
    }
};
